// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
    getAuth,
    signInWithEmailAndPassword,
} from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore, addDoc, collection } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDbiCDg7KIesy2NauswE2qJctHxDbNpTFY",
    authDomain: "yckuo-firebase-test.firebaseapp.com",
    projectId: "yckuo-firebase-test",
    storageBucket: "yckuo-firebase-test.appspot.com",
    messagingSenderId: "37259097112",
    appId: "1:37259097112:web:a2d3dcb5693b023541ff6d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();
const storage = getStorage(app);


const signIn = async (email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(
            auth,
            email,
            password
        );
        const user = userCredential.user;
        return true
    } catch (error) {
        return { error: error.message }
    }
};

export { signIn,auth,db,storage };


